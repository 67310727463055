export const languagesItems = [
  {
    id: 0,
    title: 'UA',
    value: 'ua',
  },
  {
    id: 1,
    title: 'EN',
    value: 'en',
  },
  {
    id: 2,
    title: 'DE',
    value: 'de',
  },
];
