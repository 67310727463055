export const theme = {  
  menuItem: {
    fontSize: '1.3rem',
    color: 'secondary.main',    
    backgroundColor: 'primary.main',
  },
  selectItem: {
    fontSize: '1.3rem',
    color: 'text.primary',
  },
};
